<template>
    <li class="card-list__item" :class="{'increased-padding': highlightedText}">
        <span v-if="highlightedText" class="highlighted-text">
            {{ highlightedText }}
        </span>
        <div class="row row-header">
            <div class="row-inner">
                <span
                    v-if="$validateText(categoryData.usp_label)"
                    class="usp-text"
                >
                    {{ categoryData.usp_label }}
                </span>

                <nuxt-link class="card-title" :to="$prismic.linkResolver(card)">
                    {{ card.data.title }}
                </nuxt-link>
            </div>

            <div class="card-btns">
                <toggle-compare-card :id="card.id" />

                <ButtonTarget
                    :link="card"
                    background="orange"
                    size="lg"
                />
            </div>
        </div>

        <div class="column column-card-img">
            <div>
                <nuxt-link :to="$prismic.linkResolver(card)">
                    <prismic-image
                        :img="card.data.image"
                        w="250"
                        h="156"
                    />
                </nuxt-link>
            </div>

            <card-rating 
                :rating="card.data.core_api.ratings.average_score" 
                :card="card"
            />
        </div>

        <div class="column column-data">
            <card-usp-box 
                v-if="$validateText(categoryData.usp_box_description)"
                :usp-box-title="categoryData.usp_box_title"
                :usp-box-description="categoryData.usp_box_description"
            />

            <data-boxes :model="model" />
        </div>

        <div class="row row-expand">
            <expand
                :button="$translate('card_info_and_demands', 'Kortfakta & Krav')"
                class="card-info"
            >
                <card-info :model="model" />

                <div class="mobilepayments-and-demands">
                    <card-demands 
                        :model="model"
                    />
                    <mobile-payments 
                        :model="model"
                    />
                </div>
            </expand>
            
            <expand
                v-if="$validateText(categoryData.pros) || $validateText(categoryData.cons)"
                :button="$translate('pros_and_cons', 'För- & nackdelar')"
                class="pros-cons"
            >
                <ul v-if="$validateText(categoryData.pros)">
                    <li>{{ $translate('pros', 'Fördelar') }}</li>
                    <li
                        v-for="(pro, index) in categoryData.pros"
                        :key="index"
                    >
                        <i class="icon-pros fa-regular fa-check fa-check1" />
                        <span>
                            {{ $prismic.asText([pro]) }}
                        </span>
                    </li>
                </ul>

                <ul v-if="$validateText(categoryData.cons)">
                    <li>{{ $translate('cons', 'Nackdelar') }}</li>
                    <li
                        v-for="(con, index) in categoryData.cons"
                        :key="index"
                    >
                        <i class="icon-pros fa-regular fa-xmark fa-clear" />
                        <span>
                            {{ $prismic.asText([con]) }}
                        </span>
                    </li>
                </ul>
            </expand>

            <expand
                :button="$translate('what_we_think_of_the_card', 'Vad vi tycker om kortet')"
                class="summary"
            >
                <div class="container">
                    <div v-if="author && card.data.our_opinion.length" class="container-inner">
                        <prismic-image :img="author.data.image" h="60" />
                        <div class="content">
                            <p class="summary-header"> {{ summaryHeader }}</p> 
                            <div 
                                class="rich-text"    
                                v-html="$prismic.asHtml(card.data.our_opinion)"
                            />
                            <div class="button-container">
                                <button-nuxt
                                    background="green"
                                    size="sm"
                                    :text="$translate('cta_read_more_button_text', 'Läs mer om {title}')
                                        .replace('{title}', card.data.title)" 
                                    :link="card"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-html="$prismic.asHtml(card.data.summary)" />
                        <div class="button-container">
                            <button-nuxt
                                background="green"
                                size="sm"
                                :text="$translate('cta_read_more_button_text', 'Läs mer om {title}')
                                    .replace('{title}', card.data.title)" 
                                :link="card"
                            />
                        </div>
                    </div>
                </div> 
            </expand>
        </div>
        <div v-if="$isNorway()" class="row row-compliance">
            <span class="compliance">{{ model.complianceExample }}</span>
        </div>
    </li>
</template>

<script>
import ToggleCompareCard from '@/components/buttons/ButtonToggleCompareCard.vue';
import CardInfo from './list-item-components/CardInfo.vue';
import DataBoxes from './list-item-components/DataBoxes.vue';
import CardUspBox from '@/components/features/CardUspBox.vue';
import CardRating from '@/components/features/CardRating.vue';
import Expand from './list-item-components/Expand.vue';
import MobilePayments from './list-item-components/MobilePayments.vue';
import CardDemands from './list-item-components/CardDemands.vue';
export default {
    components: {
        CardUspBox,
        CardInfo, 
        DataBoxes,
        CardRating,
        Expand,
        ToggleCompareCard,
        MobilePayments,
        CardDemands
    },
    props: {
        card: {
            type: Object,
            required: true,
            default: undefined
        },
        highlightedText: {
            type: String,
            required: false,
            default: ''
        },
        categoryDataKey: {
            type: String, 
            required: false,
            default: 'general'
        }
    },
    computed: {
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        },
        author() {
            if (this.card.author?.id) {
                return false;
            }
            return this.$store.state.authors[this.card.data.author.id];
        },
        summaryHeader() {
            return this.$translate('author_summarizes', '{name} sammanfattar')
                .replace('{name}', this.author.data.name);
        },
        categoryData() {
            return this.$getCategoryData(this.card, this.categoryDataKey);
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-list__item {
        @include border-radius(20px);
        background: $white;
        box-shadow: $bxs;
        @include spacing(padding,8);
        @include flex(start,start);
        transition: .3s ease-in-out;
        @include border(all,1px);
        position: relative;
        &.increased-padding {
            padding-top: 48px;
            @include device(pad) {
                @include spacing(padding, 8, top);
            }
        }
        .highlighted-text {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            @include border(all, white, 5px);
            @include flex();
            border-radius: 100px;
            font-weight: bold;
            background: $green;
            padding: 15px 20px;
            color: $white;
            font-size: 18px;
            white-space: nowrap;
            @include device(desktop) {
                padding: 20px 30px;
                font-size: 28px;
                border-width: 7px;
            }
        }

        .row {
            width: 100%;

            &-header {
                @media screen and (max-width: 700px) {
                    @include grid(1,20px);
                    text-align: center;
                    order: 2;
                    @include spacing(margin,6,(bottom,top));

                    .btn {
                        margin: auto;
                    }
                }

                @media screen and (min-width: 700px) {
                    @include flex(between);
                    @include spacing(margin,8,bottom);
                }

                .row-inner {
                    flex: 1;
                    @include flex;
                    @media screen and (min-width: 700px) {
                        justify-content: flex-start;
                    }

                    .card-title {
                        font-family: $fontHeader;
                        @include font-size(26px,8px);
                        width: 100%;
                        @include device(mobile) {
                            @include font-size(20px,8px);
                        }
                    }

                    .usp-text {
                        margin-bottom: 10px;
                        @include border-radius(20px);
                        padding: 10px 20px;
                        font-weight: bold;
                        font-size: 14px;
                        background: $green;
                        color: $white;
                        width: auto;
                        text-align: center;
                    }
                }

                .btn-div {
                    width: 300px;
                    @include device(mobile) {
                        width: 100%;
                    }
                }
            }

            &-expand::v-deep {
                @include spacing(margin,8,top);

                @media screen and (max-width: 700px) {
                    order: 3;
                }

                ul {

                    li {
                        @include flex(start,start);
                        @include font-size(13px, 6px);
                        @include spacing(padding,2,(top,bottom));
                        &:first-child {
                            @include font-size(14px, 8px);
                            font-weight: bold;
                        }
                        span { flex: 1; }
                        .icon-pros {
                            font-size: 17px;
                            margin-right: 10px;
                            margin-top: 2px;
                        }
                    }
                }

                .expand-row__content {
                    @include grid(1, 0px);

                    @include device(pad) {
                        @include grid(2, 0px);
                    }
                    ul {
                        background: $graybg;
                        padding: 10px 20px;
                    }
                }

                .summary {
                    .expand-row__content {
                        @include flex(start,start);
                        width: 100%;
                        
                        .container {
                            width: 100%;
                            @include flex(start,start);
                            background: $graybg;
                            padding: 20px;

                            .button-container{
                                a {
                                    width: 100%;
                                }
                            }
                            .container-inner {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .summary-header {
                                    color: $green;
                                    font-weight: bold;
                                    @include font-size(16px);
                                    margin-bottom: 5px;
                                    margin-top: 10px;
                                }
                            }
                            @include device(pad) {
                                .container-inner{
                                    flex-direction: row;
                                    align-items: flex-start;
                                }
                                .content{
                                    padding-left: 15px;
                                    margin-left: 15px;
                                    @include border(left,1px);
                                    
                                    .summary-header{
                                        margin-top: 0;
                                    }
                                }
                                .button-container{
                                    display: flex;
                                    justify-content: left;
                                    a {
                                    width: unset;
                                }
                                }
                            }  

                            .btn {
                                margin-top: 20px;
                                box-shadow: none;
                            }
                        }        
                    }
                }
            }

            &-compliance {
                @media screen and (max-width: 700px) {
                    order: 4;
                }
                margin-top: 20px;
                color: $muted;
                font-weight: lighter;
                font-size: .8em;
            }
        }

        .column {

            &-card-img {
                @media screen and (max-width: 700px) {
                    width: 100%;
                    @include flex;
                    order: 1;
                }

                img {
                    @media screen and (max-width: 700px) {}

                    @media screen and (min-width: 700px) and (max-width: 900px) {
                        width: 180px;
                        height: auto;
                    }

                    @include device(desktop) {}
                }

                a {
                    color: $font;
                    text-decoration: none;
                    img { margin-bottom: 5px; }
                }
            }

            &-data {
                @media screen and (max-width: 700px) {
                    width: 100%;
                    order: 2;
                }

                @media screen and (min-width: 700px) {
                    flex: 1;
                    padding-left: 40px;
                }
            }
        }
    }
    .mobilepayments-and-demands::v-deep {
        display: flex; 
        flex-direction: column; 
        background-color: #f9fafc;

        .payment-container {
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap: 0;
            align-items: center;
        }
    }
</style>
